import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "../../components/container"
import Section from "../../components/section"
import Typography from "../../components/typography"
import Flex from "../../components/flex"
import Card from "../../components/card"
import Button from "../../components/button"
import allSpecialties from "../../api/specialties"

const Specialties = props => (
  <Layout {...props}>
    <SEO
      title="Especialidades da equipe GastroCirúrgica em Florianópolis - SC"
      description="Confira as especialides e cirurgias realizadas pela equipe de cirurgiões da GastroCirúrgica"
    />
    <Section padding="2rem 0">
      <Container>
        <Typography variant="h1" component="h1" color="secondary">
          Especialidades
        </Typography>
        <Typography variant="h6" component="h2" color="primary">
          Confira as especialides e cirurgias realizadas pela equipe de
          cirurgiões da GastroCirúrgica
        </Typography>
      </Container>
    </Section>
    <Section padding="2.5rem 0" background="secondary">
      <Container padding="0">
        <Flex flexWrap="wrap">
          {allSpecialties.map(specialtie => (
            <Flex child flexXs={100} flex={50} spacing={1.5}>
              <Card padding="2rem" margin="0.75rem 0">
                <Typography variant="h5" component="h3" color="secondary">
                  {specialtie.name}
                </Typography>
                {specialtie.slug ? (
                  <Button to={'/' + specialtie.slug} htmlType="link" color="link">
                    Saiba mais
                  </Button>
                ) : (
                  <Typography variant="bodyLarge" component="p" removeMargin>
                    &nbsp;
                  </Typography>
                )}
              </Card>
            </Flex>
          ))}
        </Flex>
      </Container>
    </Section>
  </Layout>
)

export default Specialties
