const specialties = [
  {
    name: "Cirurgia Geral",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
      dr_wilmar_athayde_gerent: true,
    },
  },
  {
    name: "Cirurgia do Aparelho Digestivo",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
    },
  },
  {
    name: "Cirurgia Bariátrica (Cirurgia da Obesidade)",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
    },
  },
  {
    name: "Coloproctologia",
    doctors: {
      dr_cristiano_denoni_freitas: true,
    },
  },
  {
    name: "Cirurgia Minimamente Invasiva (Videoalaparoscópica / Cirurgia Robótica)",
    doctors: {
      dr_cristiano_denoni_freitas: true,
      dr_eduardo_miguel_schmidt: true,
      dr_joao_paulo_farias: true,
      dr_mauricio_mendes_albuquerque: true,
      dr_wilmar_athayde_gerent: true,
    },
  },
  {
    name: "Cirurgia Oncológica do Aparelho Digestivo",
    doctors: {
      dr_cristiano_denoni_freitas: true,
    },
  },
]

module.exports = specialties
